import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { useRef, useEffect} from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import Footer from "./Hooks/Footer";
import Loader from "./Hooks/Loader";

const About = () => {
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); 
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };
  return (
   <>
   <Loader/>
   <div className='overflow-hidden'>
   <section class="About">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="revoke.png" alt="" className="w-[40%]"/> */}
                        </div>
                </motion.div>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                               <div className='flex mt-10'>
                                <p>Home<i class="ri-arrow-right-s-line pt-1 text-[#0fc3c3]"></i></p>
                                <p>Services<i class="ri-arrow-right-s-line pt-1 text-[#0fc3c3]"></i></p>
                               </div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[3rem] font-bold text-white'>About<br />Sterling Recovery</p>
                                </motion.div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        <div className='bg-black h-fit'>
            <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-[#c8c8c8]'>
                                At Sterling Recovery we stand firmly by your side, poised to thwart the maneuvers of fraudsters and resolutely restore the fruits of your hard-earned labor. Through the fusion of our specialized expertise and cutting-edge technological prowess, we offer you an unparalleled mosaic of tranquility by reclaiming financial assets ensnared within the clutches of fraudulent schemes. Our team of seasoned professionals comprehends the profound repercussions of falling prey to scams, thus addressing each case with the finesse of a master craftsman. Meticulous in our discretion, we tailor bespoke solutions finely tuned to the unique cadence of your circumstances. Whether ensnared in the maze of online phishing, victimized by investment deceit, or ensnared by the allure of romance turned treacherous, Sterling Recovery stands ready to unravel even the most intricate of predicaments.
                                </p> <br />

                                <p className='text-[#c8c8c8]'>
                                With a history of triumphant retrievals and an expansive network of esteemed clients worldwide, we have cemented our reputation as an impregnable fortress of recovery. As we diligently toil to restore your assets, we simultaneously dispatch an unambiguous message to those who perpetrate deception: their schemes will meet swift and exacting retribution. Bestow your unwavering confidence in Sterling Recovery to redefine the landscape of romance scam recovery and illuminate the pathway to financial renewal.
                                </p>
                            </div>
                        </div>
                        <div className='bg-green-60 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://www.canberratimes.com.au/images/transform/v1/crop/frm/RXMuw2JbrrS7ELSxSY9rkR/9fa2708f-f1b7-4b4b-a059-836a4d92bf1c.jpg/r551_772_5142_3973_w5694_h3970_fmax.jpg" 
                                alt="" className='rounded-md'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mx-auto overflow-hidden  md:max-w-full mt-10'>
                <div className='Numbers'>
                    <div className='mx-auto overflow-hidden  md:max-w-7xl relative'>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[3%]'>
                                <div className='bg-green-00 h-fit'>
                                    <div>
                                        <p className='font-bold text-[1.5rem] text-white '> <span className='top__b'>Why Choose Us</span></p>
                                    </div>

                                    <div className='pt-5'>
                                        <p className='text-[25px] font-bold text-white'>Secure Asset Recovery Trust <br />
                                         <span className='text-[#0fc3c3]'>Sterling Recovery</span> </p>
                                    </div>

                                    <div><br />

                                        <p className='text-[#dfdcdc]'>
                                        Sterling Recovery is the ideal choice for asset recovery due to its expert team, cutting-edge technology, and a proven track record of successful retrievals worldwide. Their tailored solutions and swift action against fraudsters ensure the secure retrieval of assets, offering individuals a reliable pathway to financial recovery and peace of mind.
                                        </p>
                                    </div>

                                   

                                </div>
                                <div className='bg-geen-600 h-fit xl:pt-10 pt-0'>
                                    <div className='flex gap-3'>
                                        <div className='bg-white h-fit w-full rounded-md'style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                                <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#0fc3c3]'>$<AnimatedNumbers value={27}/>m+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Money Restored</p>
                                                </div>
                                            </div>
                                            <div className='bg-white h-fit w-full rounded-md' style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                            <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#0fc3c3]'><AnimatedNumbers value={12000}/>+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Relied Upon By</p>
                                                </div>
                                            </div>
                                    </div>
                                    
                                        <div className='flex gap-3 mt-3'>
                                        <div className='bg-white h-fit w-full rounded-md'style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                                <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#0fc3c3]'><AnimatedNumbers value={10000}/>+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Satisfied Clients</p>
                                                </div>
                                            </div>
                                            <div className='bg-white h-fit w-full rounded-md' style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                            <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#0fc3c3]'><AnimatedNumbers value={9000}/>+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Secured Clients</p>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>  
            </div>

            <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                    <div className='pt-5 ml-3 text-white'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Mission &<span className='top__b'> Vision</span> </span></p>
                    </div>
                <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                    <div className='bg-gren-600 h-fit Shadow__x'>
                        <div className='pl-5 pt-5'>
                            <img src="mission.png" alt="" className='w-[64px]'/>
                        </div>
                        <div className='pl-5 pt-5'>
                            <p className='text-white font-bold text-[20px]'>Our Mission</p>
                        </div>
                        <div className='pl-5 pt-1 pb-10'>
                            <p className='text-white'>
                            To establish a world where trust and digital safety prevail. We envision a future where individuals can navigate
                             the digital realm with confidence, knowing that they have a steadfast ally in Sterling Recovery. We aspire to set new 
                             standards for online security, financial restitution, and victim support, reshaping the landscape of digital trust and protection.
                            </p>
                        </div>
                    </div>
                    <div className='bg-gren-600 h-fit Shadow__x'>
                        <div className='pl-5 pt-5'>
                            <img src="opportunity.png" alt="" className='w-[64px]'/>
                        </div>
                        <div className='pl-5 pt-5'>
                            <p className='text-white font-bold text-[20px]'>Our Vision</p>
                        </div>
                        <div className='pl-5 pt-1 pb-5'>
                            <p className='text-white'>
                            To relentlessly champion the rights of victims and combat the ever-evolving landscape of financial fraud, 
                            online scams, and digital threats. We empower individuals, reclaim stolen assets, restore digital security, 
                            and provide unwavering support to those in need. Our mission is to bring financial justice and peace of mind 
                            to those affected by deception, with Sterling Recovery leading the charge.
                            </p>
                        </div>
                    </div>
                    </div>
            </div>
        </div>

        <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>


        <Footer/>

   </div>
   
   </>
  )
}

export default About
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import Navbar from "./Components/Navbar";
import Bitcoin from "./Components/Bitcoin";
import Romance from "./Components/Romance";
import Blackmail from "./Components/Blackmail";
import SocialMedia from "./Components/SocialMedia";
import PhoneUnlock from "./Components/PhoneUnlock";
import Account from "./Components/Account";
import About from "./Components/About";
import Contact from './Components/Contact';
function App() {
  return (
  <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/Navbar' element={<Navbar/>}/>
    <Route path='/Bitcoin' element={<Bitcoin/>}/>
    <Route path='/Romance' element={<Romance/>}/>
    <Route path='/Blackmail' element={<Blackmail/>}/>
    <Route path='/SocialMedia' element={<SocialMedia/>}/>
    <Route path="/PhoneUnlock" element={<PhoneUnlock/>}/>
    <Route path='/Account' element={<Account/>}/>
    <Route path='/About' element={<About/>}/>
    <Route path='/Contact' element={<Contact/>}/>
  </Routes>
  );
}

export default App;

import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { BsCheck2All } from "react-icons/bs";
import Circle from "./Hooks/Circle";
import Circlex from "./Hooks/Circlex";
import Circlexx from "./Hooks/Circlexx";
import AccountReview from "./Hooks/AccountReview";
import { Link } from 'react-router-dom';
import Footer from "./Hooks/Footer";
import Loader from "./Hooks/Loader";


const Account = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#0fc3c3'
    }
  return (
   <>
   <Loader/>
   <div className='overflow-hidden'>
            <section class="Account">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="revoke.png" alt="" className="w-[40%]"/> */}
                        </div>
                </motion.div>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                               <div className='flex mt-10'>
                                <p>Home<i class="ri-arrow-right-s-line pt-1 text-[#0fc3c3]"></i></p>
                                <p>Services<i class="ri-arrow-right-s-line pt-1 text-[#0fc3c3]"></i></p>
                               </div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[3rem] font-bold text-white'>Account<br />Activation/Unlock</p>
                                </motion.div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='bg-black h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-[#c8c8c8]'>
                                Enduring the disruption caused by losing access to vital systems can throw our daily routines into disarray and impede our advancement. Within our esteemed institution, we recognize the immediacy and significance of this predicament, prompting us to prioritize our exceptional account restoration service. Whether your need pertains to reclaiming legitimate access to personal profiles or seeking assistance within the bounds of the law to regain control of devices, our steadfast Sterling Recovery team stands ready to provide unwavering assistance throughout the entire journey. Armed with a formidable array of resources and state-of-the-art tools, we possess the proficiency to overcome even the most intricate account-related challenges.
                                </p> <br />

                                <p className='text-[#c8c8c8]'>
                                Rest assured, no platform poses a puzzle too formidable for us to unravel. Backed by our profound competence and unwavering commitment to excellence, we skillfully navigate hurdles, ensuring a swift and dependable return to your crucial profiles. Refuse to let account impediments obstruct your progress. Bestow your trust in our capabilities and empower yourself to reestablish dominion over your digital realm.
                                </p>
                            </div>
                        </div>
                        <div className='bg-green-60 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://media.istockphoto.com/id/930176848/photo/hacker-resolving-the-matrix.jpg?s=612x612&w=0&k=20&c=JNGQAmEh4uW_BZMbbk1Iwnj3ynlQmKLBFoSCpsaP7dA=" 
                                alt="" className='rounded-md'/>
                            </div>
                        </div>
                    </div>
                </div>





                <div className='bg-[#0b0c10] h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl text-white'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Services on  Account <span className='top__b'>Unlock/Activation</span> </span></p>
                    </div>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Deactivated Account</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Recliam Funds From Suspended or Locked Bank Account</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Suspended Social Media Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation Of suspended Social Media Account</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Locked Crypto wallet</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Locked Crypto wallet</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation Of suspended Social Media Account</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className='b-green-600 h-fit'>
                        <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-black"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        For reliable account unlock and recovery, entrust Sterling Recovery Experts. With our expertise, individualized support, and platform policy acumen, we skillfully navigate the path to assist you in regaining access to your valuable accounts. Choose Sterling Recovery Experts as your steadfast companion in this crucial journey.
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Account Recovery Expertise:</span>  We deliver tailored, hands-on assistance to tackle your unique challenges in account unlock and recovery. Our dedicated team collaborates closely with you, ensuring a seamless and efficient recovery process.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Customized Support:</span> We provide personalized, hands-on support to address your unique challenges in account unlock and recovery. Our dedicated team works closely with you, ensuring a seamless and efficient recovery process.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Diverse Platform Mastery:</span> From Instagram, Facebook, Twitter, TikTok, to various other social platforms, our proficiency spans a wide spectrum. We skillfully navigate the intricacies of diverse platforms, ensuring successful retrieval.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-5'> 
                                <span className='font-bold'>Platform Policy Acumen</span>   Sterling Recovery Experts boasts a deep understanding of various platform policies, streamlining the process and enabling us to unlock and recover your accounts with utmost efficiency and security.
                               
                            </p>
                        </motion.div>

                        
                    </motion.div>
                    </div>
                        </div>
                    </div>
            </div>



                <div className='mx-auto overflow-hidden  md:max-w-7xl text-[white]'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Review on Acoount   <span className='top__b'>Unlock/Activation</span> </span></p>
                    </div>
                    <AccountReview/>
                </div>
                </div>


                
                
            </div>


            <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>

   </div>
   
   
   <Footer/>
   
   
   </>
  )
}

export default Account;
import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { BsCheck2All } from "react-icons/bs";
import Circle from "./Hooks/Circle";
import Circlex from "./Hooks/Circlex";
import Circlexx from "./Hooks/Circlexx";
import PhoneReview from "./Hooks/PhoneReview";
import { Link } from 'react-router-dom';
import Footer from "./Hooks/Footer";
import Loader from "./Hooks/Loader";


const PhoneUnlock = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#0fc3c3'
    }
  return (
   <>
   <Loader/>
   <div className='overflow-hidden'>
   <section class="Phone">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="revoke.png" alt="" className="w-[40%]"/> */}
                        </div>
                </motion.div>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                               <div className='flex mt-10'>
                                <p>Home<i class="ri-arrow-right-s-line pt-1 text-[#0fc3c3]"></i></p>
                                <p>Services<i class="ri-arrow-right-s-line pt-1 text-[#0fc3c3]"></i></p>
                               </div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[3rem] font-bold text-white'>Phone & Device<br />Hack/Unlock</p>
                                </motion.div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='bg-black h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-[#c8c8c8]'>
                                Traversing through technology sometimes poses a challenging feat, and encountering locked devices due to potential threats can be incredibly vexing. Yet, fear not, for we unveil an optimal solution that will soothe your worries. Encounter uninterrupted access to your favored devices, irrespective of carrier locks, and bid farewell to the vexations accompanying device lockouts. Our adept team is prepared to guarantee a seamless unlocking process, granting you the delight of swift device utilization. Place your trust in us to deliver efficient and dependable unlocking services, allowing you to regain control and unlock the full potential of your beloved devices.
                                Our adept team is prepared to guarantee a seamless unlocking process, granting you the delight of swift device utilization. Place your trust in us to deliver efficient and dependable unlocking services, allowing you to regain control and unlock the full potential of your beloved devices.
                                </p> <br />

                                <p className='text-[#c8c8c8]'>
                                In our innovative hub, we comprehend the complexities of contemporary technology, and our state-of-the-art methodologies assure a hassle-free unlocking experience. The inconvenience of device lockouts will no longer perturb you. Our inventive solutions cater to a diverse array of devices, ensuring ongoing liberation. With a myriad of contented clients and years of industry expertise, we stand as the foremost authorities in unlocking services
                                </p>
                            </div>
                        </div>
                        <div className='bg-green-60 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://media.istockphoto.com/id/930176848/photo/hacker-resolving-the-matrix.jpg?s=612x612&w=0&k=20&c=JNGQAmEh4uW_BZMbbk1Iwnj3ynlQmKLBFoSCpsaP7dA=" 
                                alt="" className='rounded-md'/>
                            </div>
                        </div>
                    </div>
                </div>





                <div className='bg-[#0b0c10] h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl text-white'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Services on Device <span className='top__b'>Unlock/Hack</span> </span></p>
                    </div>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iMac, Mac Book & iPad Unlock/Hack</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iCloud Unlock</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Phone Tracking</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Android Hack/Unlock</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iOS Hack/Unlock</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Laptop & Phone Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Laptop & Phone Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iOS Hack/Unlock</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className='b-green-600 h-fit'>
                        <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-black"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        Realizing the complete potential of your phone or device relies on unlocking it effectively. Sterling Recovery presents a streamlined and reliable solution, setting us apart in the realm of phone/device unlocking. Here's why opting for us for your unlocking and bypassing necessities is a wise choice
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Skill and Know-how:</span> Our experienced team possesses extensive expertise and practical experience in the intricate realm of phone/device unlocking. With years of industry insight, we adeptly navigate complexities.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Demonstrated Achievements:</span> With a history of successful solutions, they reveal a legacy of efficiently retrieving lost funds for clients.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Serenity:</span> Unlocking or bypassing your device can notably augment its functionality, opening new possibilities. We assure the peace of mind that emerges from entrusting your device to capable hands, ensuring the utmost confidentiality for your data.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Esteemed Reputation:</span>  
                                We've garnered a respected reputation in the field, supported by a satisfied customer base affirming our reliability and professionalism. Our track record underscores our commitment to excellence.
                            </p>
                        </motion.div>

                        
                    </motion.div>
                    </div>
                        </div>
                    </div>
            </div>



                <div className='mx-auto overflow-hidden  md:max-w-7xl text-[white]'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Review on Social Media  <span className='top__b'>Hack/Recovery</span> </span></p>
                    </div>
                    <PhoneReview/>
                </div>
                </div>


                
                
            </div>


            <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>

   </div>
   
   
   <Footer/>
   
   
   </>
  )
}

export default PhoneUnlock;
import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { BsCheck2All } from "react-icons/bs";
import Circle from "./Hooks/Circle";
import Circlex from "./Hooks/Circlex";
import Circlexx from "./Hooks/Circlexx";
import BlackmailReview from "./Hooks/BlackmailReview";
import { Link } from 'react-router-dom';
import Footer from "./Hooks/Footer";
import Loader from "./Hooks/Loader";


const Blackmail = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#0fc3c3'
    }
  return (
   <>
   <Loader/>
   <div className='overflow-hidden'>
   <section class="Blackmail">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="revoke.png" alt="" className="w-[40%]"/> */}
                        </div>
                </motion.div>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                               <div className='flex mt-10'>
                                <p>Home<i class="ri-arrow-right-s-line pt-1 text-[#0fc3c3]"></i></p>
                                <p>Services<i class="ri-arrow-right-s-line pt-1 text-[#0fc3c3]"></i></p>
                               </div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[3rem] font-bold text-white'>Help From <br />Blackmail/Extortion</p>
                                </motion.div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='bg-black h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-[#c8c8c8]'>
                                Sterling Recovery is a staunch advocate in combatting the pernicious acts of blackmail and extortion. Our 
                                focus lies in extending support to individuals ensnared in the distressing clutches of these nefarious 
                                practices. We understand the emotional and psychological turmoil victims often endure when faced with 
                                the threats and manipulation inherent in blackmail and extortion situations. Through our unwavering 
                                dedication, we aim to empower and assist these individuals on their path towards recovery and justice.
                                </p> <br />

                                <p className='text-[#c8c8c8]'>
                                If you or someone you know is grappling with the daunting effects of blackmail or extortion, we urge you 
                                to take a stride towards reclaiming your life. Reach out to us at Sterling Recovery, where our team of specialists 
                                is wholeheartedly dedicated to offering guidance, resources, and personalized solutions to navigate these 
                                challenges. Through our holistic approach, we aspire to dismantle the power dynamics wielded by those who 
                                perpetrate blackmail and extortion. At Sterling Recovery, we stand firm in our mission to foster a safer 
                                environment and a brighter future for all those impacted by these harmful actions.
                                </p>
                            </div>
                        </div>
                        <div className='bg-green-60 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://barbroose.com/wp-content/uploads/2018/02/bigstock-people-emotions-stress-and-h-97858355-1024x683.jpg" 
                                alt="" className='rounded-md'/>
                            </div>
                        </div>
                    </div>
                </div>





                <div className='bg-[#0b0c10] h-fit'>
                    <div className='mx-auto overflow-hidden  md:max-w-7xl text-white '>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Services on Blackmail /<span className='top__b'>Extortion</span> </span></p>
                    </div>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2  sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Money Recovery From Blackmail/Extortion</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Help from Online Threats</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sextortion Help</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Extortion help</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Help from Blackmail</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div> */}
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Text Messages Hack/Spy</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Help from Blackmail</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                      <i class="ri-checkbox-circle-fill text-[#0fc3c3]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className='b-green-600 h-fit'>
                        <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-black"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        With Sterling Recovery leading the way and our dedicated team by your side, we stand as your steadfast ally in liberating yourself from the hold of blackmail. Our resolute dedication to quality, along with our team's profound grasp of the intricacies of recovery, sets us apart. Here's why opting for us is the prime choice for you and your assets:
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Expertise in Intricate Recovery:</span> With vast expertise in navigating complex recovery scenarios, we are prepared to lead you through the intricacies of blackmail and extortion recovery. Count on our seasoned team to pave the way.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Comprehensive Protective Solutions:</span> Our commitment extends beyond recovery; it includes fortifying your future. Explore our holistic security measures crafted to prevent future threats and safeguard your assets.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Proven Track Record:</span> With a history of successful outcomes, they showcase a legacy of efficiently reclaiming lost funds for clients.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#0fc3c3]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Confidentiality and Assurance:</span>  
                                rust is the cornerstone of our bond. Rely on us to handle your case with the utmost confidentiality and discretion, ensuring your peace of mind during the recovery process.
                            </p>
                        </motion.div>

                        
                    </motion.div>
                    </div>
                        </div>
                    </div>
                </div>



                <div className='mx-auto overflow-hidden  md:max-w-7xl text-[white]'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Review on Blackmail  <span className='top__b'>Recovery</span> </span></p>
                    </div>
                    <BlackmailReview/>
                </div>
                </div>


                
                
            </div>


            <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>

   </div>
   
   
   <Footer/>
   
   
   </>
  )
}

export default Blackmail;
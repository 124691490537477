import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV0GquCD06fzkwMM30Kjves5p35WX7gIF0Rw&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Peregrine Stirling</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[5%] pb-5 pr-1 pl-1'>
            For several months, I found myself entangled in a web of deceit as I invested over $46,000 in what turned out to be a fraudulent broker investment platform. Realizing the scam, I felt helpless until I reached out to Sterling Recovery. Grateful is an understatement for the expertise of this incredible team. They not only restored my lost money but also took down the scam website. Kudos to Sterling Recovery for turning the tables and bringing justice to those ensnared in fraudulent schemes.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-2'>seattle, Washington USA🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGbd7DZNi3nUoJoJXHUZIl5QOBUsGWY42pbg&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Lucian Treadwell</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[5%] pb-5 pr-1 pl-1'>
            In a cruel twist of fate, I found myself scammed of $35,000 while attempting to recover my previous losses. It was a painful double hit. However, a glimmer of hope emerged when I stumbled upon a recommendation for Sterling Recovery. This remarkable team showcased their expertise by not only restoring the initial sum that was scammed but also recovering the additional $35,000 taken by the fake recovery firm. A heartfelt thank you to Sterling Recovery for turning a desperate situation around and delivering real results
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-0'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-0'>Detroit, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://cache.legacy.net/legacy/images/cobrands/nhregister/photos/CT0121754-1_20221105.jpgx?w=367&h=500&option=3' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Quintrell Ashcroft</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[3%] pb-5 pr-1 pl-1'>
            For months, I lived under the constant threat of blackmail by an online scammer who possessed my private photos, extorting money from me every week. Fearing for my marriage, I reluctantly paid the scammer. However, a turning point came when I was recommended to Sterling Recovery This exceptional team not only restored my money but also ensured justice was served by bringing the scammer to account. Thanks to the expertise of Sterling Recovery, I am forever grateful for reclaiming control over my life and protecting what matters most.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-2'>Nashville, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://people.com/thmb/oJXxlDqfuDrBVKNckK8b8C0Uc-Q=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(509x0:511x2)/screen-shot-2017-09-18-at-11-45-30-am-5f91d693bec0483991eb1a50eb4533b4.png' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Fioralise Beaumont</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[2%] pb-5 pr-1 pl-1'>
            In the pursuit of love, I met a man online, and over the course of eight months, I showered him with affection in the form of gift cards, totaling $57,000. Unfortunately, the love I believed in turned out to be a scam. When I confronted him to return the money, he refused. It was at this desperate moment that a friend recommended Sterling Recovery. This group of experts not only restored my hard-earned money but also brought the scammer to justice. My heartfelt thanks to Sterling Recovery for their expertise and for being the light in the darkness of deception.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-2'>Tampa, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://www.advocate.com/sites/advocate.com/files/2015/07/23/Jenner400d.jpg.png' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Meridian Forsythe</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[8%] pb-5 pr-1 pl-1'>
            In the midst of suspicions about my husband's fidelity, I yearned for concrete evidence to confront him. Uncertain about how to proceed, I was directed to Sterling Recovery. This remarkable team not only provided me with remote access to his phone but also empowered me with the proof I needed. Thanks to these experts, I now have the clarity to confront my husband and uncover the truth. Kudos to Sterling Recovery for their invaluable assistance in revealing the reality behind my doubts.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-2'>Oakville, Canada  🇨🇦 </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
